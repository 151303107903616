import { FormikErrors, FormikTouched, getIn } from "formik";
import { forwardRef, Ref, useMemo, useState } from "react";
import styled from "styled-components";
import OpeningCalendarButton from "./OpeningCalendarButton";
import CustomDayPicker from "./CustomDayPicker";
import { CustomDatePickerSource } from "../../enums/CustomDatePicker";
import { Animations } from "../shared/MobileModal";
import { DEFAULT_DISABLED_DAYS } from "../../constants/flightsConstants";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { useTranslation } from "../../i18n";
import DateFromIcon from "../../customIcons/DateFrom";
import DateToIcon from "../../customIcons/DateTo";

interface Props<T> {
  onChange(date: Date, source: CustomDatePickerSource, isSelectingFirstDay: boolean): void;
  isLastFieldInRow?: boolean;
  shouldShowRange?: boolean;
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  values: T;
  fromFieldName: string;
  toFieldName?: string;
  fromPlaceholderLabel: string;
  toPlaceholderLabel?: string;
  disabledDays?: { before: Date; after: Date };
  scrollControlled?: boolean;
  mobileLabels?: { from: string; to: string; edit: string };
  isFlightDatepicker?: boolean;
  mobileModalAnimations?: Animations;
  onMobileModalClose?: () => void;
}

const CustomDateFields = forwardRef<HTMLButtonElement, Props<unknown>>(
  <T,>(
    {
      onChange,
      isLastFieldInRow = false,
      shouldShowRange = false,
      errors,
      touched,
      values,
      fromFieldName,
      toFieldName,
      fromPlaceholderLabel,
      toPlaceholderLabel,
      disabledDays = DEFAULT_DISABLED_DAYS,
      scrollControlled,
      mobileLabels,
      isFlightDatepicker,
      mobileModalAnimations,
      onMobileModalClose,
    }: Props<T>,
    ref?: Ref<HTMLButtonElement>
  ) => {
    const formatDate = useDateFormatter();
    const { t } = useTranslation();

    const [isFromOpen, setIsFromOpen] = useState(false);
    const [isToOpen, setIsToOpen] = useState(false);

    const fromValue = useMemo(() => {
      return getIn(values, fromFieldName);
    }, [fromFieldName, values]);

    const toValue = useMemo(() => {
      if (!toFieldName) return null;
      return getIn(values, toFieldName);
    }, [toFieldName, values]);

    const fromLabel = useMemo(() => {
      return fromValue ? formatDate(fromValue, "shortDateWithYear") : t(fromPlaceholderLabel);
    }, [fromValue, formatDate, t, fromPlaceholderLabel]);

    const toLabel = useMemo(() => {
      if (!toPlaceholderLabel) return null;
      return toValue ? formatDate(toValue, "shortDateWithYear") : t(toPlaceholderLabel);
    }, [toValue, formatDate, t, toPlaceholderLabel]);

    return (
      <Container isLastFieldInRow={isLastFieldInRow}>
        <ButtonsContainer>
          <OpeningCalendarButton
            ref={ref}
            isOpen={isFromOpen}
            setIsOpen={(isOpen) => {
              if (isToOpen) setIsToOpen(false);
              setIsFromOpen(isOpen);
            }}
            icon={<DateFromIcon />}
            label={fromLabel}
            shouldShowRange={shouldShowRange}
            hasError={getIn(touched, fromFieldName) && !!getIn(errors, fromFieldName)}
            isPlaceholder={!getIn(values, fromFieldName)}
            isLastFieldInRow={isLastFieldInRow}
          />
          {shouldShowRange && toFieldName && (
            <OpeningCalendarButton
              isOpen={isToOpen}
              setIsOpen={(isOpen) => {
                if (isFromOpen) setIsFromOpen(false);
                setIsToOpen(isOpen);
              }}
              icon={<DateToIcon />}
              label={toLabel}
              shouldShowRange={shouldShowRange}
              hasError={getIn(touched, toFieldName) && !!getIn(errors, toFieldName)}
              isPlaceholder={!getIn(values, toFieldName)}
              isLastFieldInRow={isLastFieldInRow}
            />
          )}
        </ButtonsContainer>
        <CustomDayPicker
          isFromOpen={isFromOpen}
          setIsFromOpen={setIsFromOpen}
          isToOpen={isToOpen}
          setIsToOpen={setIsToOpen}
          onChange={onChange}
          disabledDays={disabledDays}
          fromDate={fromValue}
          toDate={toValue}
          shouldShowRange={shouldShowRange}
          mobileLabels={mobileLabels}
          scrollControlled={scrollControlled}
          isFlightDatepicker={isFlightDatepicker}
          mobileModalAnimations={mobileModalAnimations}
          onMobileModalClose={onMobileModalClose}
        />
      </Container>
    );
  }
);

const Container = styled.div<{ isLastFieldInRow: boolean }>`
  position: relative;
  width: 100%;
  margin-bottom: 10px;

  @media ${({ theme }) => theme.breakpoints.minTablet} {
    margin-right: 9px;
  }

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    margin: 0 -1px 9px 0;
    flex-shrink: 0;

    ${({ isLastFieldInRow, theme }) =>
      !isLastFieldInRow
        ? `
    &::after {
      border-right: 1px solid ${theme.defaultColor};
      content: "";
      height: 20px;
      position: absolute;
      right: 1px;
      top: 14px;
    }
    `
        : ""}
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default CustomDateFields;
