export enum WidgetDispatchEvents {
  WidgetError = "search_form_widget-widget_error",
  ValuesChanged = "search_form_widget-values_changed",
  OnSubmit = "search_form_widget-on_submit",
}

export enum WidgetListeningEvents {
  SetValues = "search_form_widget-set_values",
}

export enum WidgetGATriggerEvents {
  FlightTabSelected = "search_form_widget-flight_tab_selected",
  HotelTabSelected = "search_form_widget-hotel_tab_selected",
  OneWaySelected = "search_form_widget-one_way_flight_selected",
  ReturnSelected = "search_form_widget-return_flight_selected",
  MulticitySelected = "search_form_widget-multicity_flight_selected",
  AutocompleteAirportRequest = "search_form_widget-autocomplete_airport_request",
  FlightDestinationAirportSelected = "search_form_widget-destination_airport_selected",
  FlightDeparuteAirportSelected = "search_form_widget-departure_airport_selected",
  FlightFromDateSelected = "search_form_widget-flight_from_date_selected",
  FlightToDateSelected = "search_form_widget-flight_to_date_selected",
  OpenFlightExtendedSearch = "search_form_widget-open_flight_extended_search",
  FlightExtendedSearchAdultAdded = "search_form_widget-flight_extended_search_adult_added",
  FlightExtendedSearchAdultRemoved = "search_form_widget-flight_extended_search_adult_removed",
  FlightExtendedSearchChildAdded = "search_form_widget-flight_extended_search_child_added",
  FlightExtendedSearchChildRemoved = "search_form_widget-flight_extended_search_child_removed",
  FlightExtendedSearchInfantAdded = "search_form_widget-flight_extended_search_infant_added",
  FlightExtendedSearchInfantRemoved = "search_form_widget-flight_extended_search_infant_removed",
  FlightExtendedSearchClassChanged = "search_form_widget-flight_extended_search_class_changed",
  AutocompleteHotelsRequest = "search_form_widget-autocomplete_hotels_request",
  HotelsDestinationSelected = "search_form_widget-flight_hotels_destination_selected",
  HotelsFromDateSelected = "search_form_widget-hotels_from_date_selected",
  HotelsToDateSelected = "search_form_widget-hotels_to_date_selected",
  OpenHotelsExtendedSearch = "search_form_widget-open_hotels_extended_search",
  HotelsExtendedSearchAdultAdded = "search_form_widget-hotels_extended_search_adult_added",
  HotelsExtendedSearchAdultRemoved = "search_form_widget-hotels_extended_search_adult_removed",
  HotelsExtendedSearchChildAdded = "search_form_widget-hotels_extended_search_child_added",
  HotelsExtendedSearchChildRemoved = "search_form_widget-hotels_extended_search_child_removed",
  HotelsExtendedSearchChildAgeSelected = "search_form_widget-hotels_extended_search_child_age_selected",
  HotelsExtendedSearchChildAgeEdit = "search_form_widget-hotels_extended_search_child_age_edit",
  HotelsExtendedSearchAddRoom = "search_form_widget-hotels_extended_search_add_room",
  HotelsExtendedSearchRemoveRoom = "search_form_widget-hotels_extended_search_remove_room",
}

export type AllDispatchedWidgetEvents = WidgetDispatchEvents | WidgetGATriggerEvents;
