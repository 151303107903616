import { DateUtils } from "react-day-picker";
import { FormikErrors, FormikTouched, getIn } from "formik";
import { Ref, forwardRef, useCallback, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Breakpoint } from "../../../enums/Breakpoint";
import useMedia from "../../../hooks/useMedia";
import MobileModal, { Animations } from "../../shared/MobileModal";
import ExternalLayoutDayPickerDayPicker from "./ExternalLayoutDayPicker";
import { CustomDatePickerSource } from "../../../enums/CustomDatePicker";
import { useDateFormatter } from "../../../hooks/useDateFormatter";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { DEFAULT_DISABLED_DAYS } from "../../../constants/flightsConstants";
import { useTranslation } from "../../../i18n";
import Mk1Typography from "../../shared/Mk1Typography";
import { LayoutType } from "../../../enums/LayoutType";
import MobileModalInputHeader from "../../FlightsSearchForm/ExternalLayout/MobileModalInputHeader";
import { capitalize } from "../../../utils/stringUtils";
import { isSafari } from "../../../utils/browsersUtils";

interface Props<T> {
  onChange(date: Date, source: CustomDatePickerSource, isSelectingFirstDay: boolean): void;
  isLastFieldInRow?: boolean;
  shouldShowRange?: boolean;
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  values: T;
  fromFieldName: string;
  toFieldName?: string;
  fromPlaceholderLabel: string;
  toPlaceholderLabel?: string;
  inputTitleLabel?: string;
  disabledDays?: { before: Date; after: Date };
  scrollControlled?: boolean;
  alignLeftPx?: number;
  onMobileModalClose?: () => void;
  mobileModalAnimations?: Animations;
  isWizard: boolean;
  fromMobileTitleLabelKey: string;
  toMobileTitleLabelKey: string;
}

const ExternalLayoutInputDateFields = forwardRef<HTMLButtonElement, Props<unknown>>(
  <T,>(
    {
      onChange,
      isLastFieldInRow = false,
      shouldShowRange = false,
      errors,
      touched,
      values,
      fromFieldName,
      toFieldName,
      fromPlaceholderLabel,
      toPlaceholderLabel,
      inputTitleLabel,
      disabledDays = DEFAULT_DISABLED_DAYS,
      scrollControlled,
      alignLeftPx,
      onMobileModalClose,
      mobileModalAnimations,
      isWizard,
      fromMobileTitleLabelKey,
      toMobileTitleLabelKey,
    }: Props<T>,
    ref?: Ref<HTMLButtonElement>
  ) => {
    const formatDate = useDateFormatter();
    const { t } = useTranslation();
    const isMinTablet = useMedia(Breakpoint.MinTablet);
    const fromRef = useRef<HTMLDivElement>(null);
    const toRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();

    const [isFromOpen, setIsFromOpen] = useState(false);
    const [isToOpen, setIsToOpen] = useState(false);

    const close = useCallback(() => {
      setIsFromOpen(false);
      setIsToOpen(false);
    }, []);

    const closeModal = useCallback(() => {
      close();
      onMobileModalClose?.();
    }, [close, onMobileModalClose]);

    // close on outside click
    const closeFrom = useCallback(() => setIsFromOpen(false), []);
    useOutsideClick(fromRef, closeFrom);
    const closeTo = useCallback(() => setIsToOpen(false), []);
    useOutsideClick(toRef, closeTo);

    const fromValue = useMemo(() => {
      return getIn(values, fromFieldName);
    }, [fromFieldName, values]);

    const toValue = useMemo(() => {
      if (!toFieldName) return null;
      return getIn(values, toFieldName);
    }, [toFieldName, values]);

    const datesLabel = useMemo(() => {
      const fromLabel = fromValue
        ? formatDate(fromValue, "externalLayoutNamedShortDateWithDay")
        : t(fromPlaceholderLabel);
      const toLabel =
        shouldShowRange &&
        (toValue
          ? formatDate(toValue, "externalLayoutNamedShortDateWithDay")
          : toPlaceholderLabel
          ? t(toPlaceholderLabel)
          : null);
      return `${fromLabel} ${toLabel ? `- ${toLabel}` : ""}`;
    }, [
      fromValue,
      formatDate,
      t,
      fromPlaceholderLabel,
      toValue,
      toPlaceholderLabel,
      shouldShowRange,
    ]);

    const isSelectingFirstDay = useCallback(
      (day: Date, source: CustomDatePickerSource) => {
        const isBeforeFirstDay = fromValue && DateUtils.isDayBefore(day, fromValue);
        const isRangeSelected =
          source === CustomDatePickerSource.FROM_DATE && !!fromValue && !!toValue;
        return !fromValue || isBeforeFirstDay || isRangeSelected;
      },
      [fromValue, toValue]
    );

    const onFromClick = useCallback(
      (date: Date) => {
        onChange(
          date,
          CustomDatePickerSource.FROM_DATE,
          isSelectingFirstDay(date, CustomDatePickerSource.FROM_DATE)
        );
        setIsFromOpen(false);
        !!toFieldName && shouldShowRange && setIsToOpen(true);
      },
      [onChange, toFieldName, shouldShowRange, isSelectingFirstDay]
    );

    const onToClick = useCallback(
      (date: Date) => {
        const firstDaySelected = isSelectingFirstDay(date, CustomDatePickerSource.TO_DATE);
        onChange(date, CustomDatePickerSource.TO_DATE, firstDaySelected);
        if (!shouldShowRange) close();
        else if (!firstDaySelected) close();
      },
      [onChange, shouldShowRange, isSelectingFirstDay, close]
    );

    const onMobileClick = useCallback(
      (date: Date) => {
        if (isFromOpen) onFromClick(date);
        else onToClick(date);
      },
      [isFromOpen, onFromClick, onToClick]
    );

    return (
      <Container isLastFieldInRow={isLastFieldInRow}>
        <ButtonsContainer>
          <ButtonContainer shouldShowRange={shouldShowRange}>
            {inputTitleLabel && <InputTitle>{t(inputTitleLabel)}</InputTitle>}
            <Button
              ref={ref}
              isLastFieldInRow={isLastFieldInRow}
              hasError={getIn(touched, fromFieldName) && !!getIn(errors, fromFieldName)}
              isPlaceholder={!getIn(values, fromFieldName)}
              onFocus={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setIsFromOpen((isOpened) => !isOpened);
              }}
              onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setIsFromOpen((isOpened) => !isOpened);
              }}
              type="button"
            >
              <Placeholder>{datesLabel}</Placeholder>
            </Button>
            {isMinTablet && isFromOpen && (
              <Dropdown ref={fromRef} hasTwoDates={shouldShowRange} alignLeftPx={alignLeftPx}>
                {inputTitleLabel && <DropdownPlaceholder>{t(inputTitleLabel)}</DropdownPlaceholder>}
                <DatesLabel isHeavy={true}>{datesLabel}</DatesLabel>
                <ExternalLayoutDayPickerDayPicker
                  onDayClick={onFromClick}
                  fromDate={fromValue}
                  toDate={toValue}
                  shouldShowRange={shouldShowRange}
                  canChangeMonth={true}
                  numberOfMonths={2}
                  disabledDays={disabledDays}
                />
              </Dropdown>
            )}
          </ButtonContainer>
          {shouldShowRange && toFieldName && (
            <ButtonContainer shouldShowRange={shouldShowRange}>
              {isMinTablet && isToOpen && (
                <Dropdown
                  ref={toRef}
                  alignTop
                  hasTwoDates={shouldShowRange}
                  alignLeftPx={alignLeftPx}
                >
                  {inputTitleLabel && (
                    <DropdownPlaceholder>{t(inputTitleLabel)}</DropdownPlaceholder>
                  )}
                  <DatesLabel isHeavy={true}>{datesLabel}</DatesLabel>
                  <ExternalLayoutDayPickerDayPicker
                    onDayClick={onToClick}
                    fromDate={fromValue}
                    toDate={toValue}
                    shouldShowRange={shouldShowRange}
                    canChangeMonth={isMinTablet}
                    numberOfMonths={2}
                    disabledDays={disabledDays}
                    takeStartingMonthFrom={CustomDatePickerSource.TO_DATE}
                  />
                </Dropdown>
              )}
            </ButtonContainer>
          )}
        </ButtonsContainer>
        {!isMinTablet && (
          <MobileModal
            layout={LayoutType.External}
            headerContent={
              <MobileModalInputHeader
                inputTitle={capitalize(
                  t(isFromOpen ? fromMobileTitleLabelKey : toMobileTitleLabelKey)
                )}
              />
            }
            action={{ label: t("tix_search_form_continue"), onClick: closeModal }}
            isOpened={isFromOpen || isToOpen}
            onClose={closeModal}
            scrollControlled={scrollControlled}
            animations={mobileModalAnimations}
            isWizard={isWizard}
            style={{
              content: { backgroundColor: theme.mobileModal.backgroundColor, padding: "0 20px" },
            }}
          >
            <ExternalLayoutDayPickerDayPicker
              onDayClick={onMobileClick}
              fromDate={fromValue}
              toDate={toValue}
              shouldShowRange={shouldShowRange}
              canChangeMonth={isMinTablet}
              disabledDays={disabledDays}
              numberOfMonths={13}
              takeStartingMonthFrom={
                isFromOpen ? CustomDatePickerSource.FROM_DATE : CustomDatePickerSource.TO_DATE
              }
            />
          </MobileModal>
        )}
      </Container>
    );
  }
);

const Container = styled.div<{ isLastFieldInRow: boolean }>`
  position: relative;
  width: 100%;
`;

const ButtonsContainer = styled.div``;

const Placeholder = styled.span`
  font-family: ${({ theme }) => theme.heavyFont};
  line-height: 17px;
`;

const DropdownPlaceholder = styled(Mk1Typography).attrs({ defaultVariant: "small" })`
  margin: -5px 0 4px 0px;
  line-height: 17px;
`;

const DatesLabel = styled(Mk1Typography)`
  margin-bottom: 34px;
  padding-bottom: 20px;
  border-bottom: 1px solid;
  ${() => (isSafari() ? "margin-top: -1px" : "")};
`;

const ButtonContainer = styled.div<{ shouldShowRange: boolean }>`
  position: relative;
  width: 100%;

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    && {
      margin: 0;
    }
  }
`;

const Button = styled.button<{
  hasError?: boolean;
  isPlaceholder: boolean;
  isLastFieldInRow?: boolean;
}>`
  align-items: center;
  appearance: none;
  background: ${({ theme }) => theme.defaultBackground};
  border: none;
  color: ${({ hasError, isPlaceholder, theme }) =>
    hasError
      ? theme.errorColor
      : isPlaceholder
      ? theme.form.textField.placeholderColor
      : theme.defaultColor};
  cursor: text;
  display: flex;
  font-family: ${({ theme }) => theme.regularFont};
  font-size: 13px;
  padding-left: 20px;
  padding-top: 10px;
  position: relative;
  text-align: left;
  width: 100%;
  height: 70px;
  border-radius: ${({ theme }) => theme.ovalBorderRadius};

  @media ${({ theme }) => theme.breakpoints.minTablet} {
    height: 80px;
    padding-top: 0;
  }

  svg {
    left: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media ${({ theme }) => theme.breakpoints.minDesktop} {
      left: 10px;
    }
  }
`;

const InputTitle = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 11px;
  line-height: 17px;
  z-index: 1;
`;

const Dropdown = styled.div<{ alignTop?: boolean; alignLeftPx?: number; hasTwoDates?: boolean }>`
  background: ${({ theme }) => theme.defaultBackground};
  box-shadow: 0px 0px 20px ${({ theme }) => theme.form.dropdown.boxShadow};
  box-sizing: border-box;
  padding: 20px 25px 25px 25px;
  position: absolute;
  right: unset;
  z-index: 2;
  border-radius: 10px;

  /* top: ${({ alignTop }) => (alignTop ? "-72px" : "-2px")};
  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    top: ${({ alignTop }) => (alignTop ? "-82px" : "-2px")};
    left: -2px;
  }
  ${({ alignLeftPx }) => alignLeftPx && `left: ${alignLeftPx}px`}; */

  top: ${({ alignTop }) => (alignTop ? "-75px" : "-5px")};
  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    top: ${({ alignTop }) => (alignTop ? "-85px" : "-5px")};
    left: -5px;
  }
  ${({ alignLeftPx }) => alignLeftPx && `left: ${alignLeftPx}px`};
`;

export default ExternalLayoutInputDateFields;
