import { DefaultTheme } from "styled-components";
import { Breakpoint } from "../enums/Breakpoint";
import { AvailableFont } from "../enums/AvailableFonts";

// Figma color pallete
const PRIMARY_COLOR = "#CF2E2E";
const PRIMARY_HOVER_COLOR = "#F3CBCB";
const COMMON_DARK_COLOR = "#3D3A37";
const COMMON_LIGHT_COLOR = "#F6F6F6";
const COMMON_LIGHT_COLOR_HOVER = "#BFBFBF";
const CTA_COLOR = "#0693E3";
const CTA_HOVER_COLOR = "#007CC2";

export const gvtTheme: DefaultTheme = {
  defaultBackground: "#FFFFFF",
  transparentDefaultBackground: "rgba(255, 255, 255, 0.50)",
  defaultColor: COMMON_DARK_COLOR,
  greyColor: "#757575",
  errorColor: "#C00051",
  disabledColor: "#cacccd",
  darkBackground: COMMON_LIGHT_COLOR,
  lightBackground: "#F2F2F2",
  progressColor: "#00AFEF",
  primaryBackground: "#2C53BA",
  primaryColor: "#FFFF",
  checkmarkColor: CTA_COLOR,
  regularFont: `"${AvailableFont.DinRoundProRegular}", sans-serif`,
  heavyFont: `"${AvailableFont.DinRoundProBlack}", sans-serif`,
  sharpBorderRadius: "10px",
  ovalBorderRadius: "10px",
  searchFormTabs: {
    default: {
      activeBackgroundColor: COMMON_LIGHT_COLOR,
    },
    mobile: {
      activeBackgroundColor: "#d8d8d8",
    },
  },
  form: {
    container: {
      mobileBackgroundColor: "#D8D8D8",
    },
    textField: {
      placeholderColor: COMMON_LIGHT_COLOR_HOVER,
    },
    radioGroup: {
      disabled: "#cacccd",
    },
    switcher: {
      activeItemBackgroundColor: PRIMARY_COLOR,
      activeTextColor: "#ffffff",
      inactiveTextColor: COMMON_DARK_COLOR,
    },
    button: {
      primaryBackground: CTA_COLOR,
      primaryColor: "#FFFF",
      primaryHoverBackground: CTA_HOVER_COLOR,
      defaultHoverBackground: "#D9D9D9",
    },
    selectField: {
      defaultItemHoverBackground: COMMON_LIGHT_COLOR,
    },
    calendar: {
      hoverBackground: COMMON_LIGHT_COLOR,
      mobileDayHoverBackground: "#D9D9D9",
      mobileDayRectBackground: COMMON_LIGHT_COLOR,
      dayRectBackground: "#FFFFFF",
      activeBackground: PRIMARY_COLOR,
      selectedColor: "#FFFFFF",
      slider: {
        activeMonthsBackground: PRIMARY_COLOR,
        activeMonthsColor: "#FFFFFF",
        nonActiveMonthsColor: "#757575",
      },
    },
    dropdown: {
      boxShadow: "#00000033",
    },
    animatedRoundedSelect: {
      defaultBackgroundColor: COMMON_LIGHT_COLOR,
      hoveredOptionBackground: COMMON_LIGHT_COLOR,
      selectedOptionBackground: "#D9D9D9",
    },
  },
  flightSearchForm: {
    backgroundColor: COMMON_LIGHT_COLOR,
    flightTypeSelectedColor: PRIMARY_COLOR,
    flightTypeEmptyColor: COMMON_DARK_COLOR,
    buttonColor: CTA_COLOR,
  },
  mobileModal: {
    backgroundColor: COMMON_LIGHT_COLOR,
    footerBackgroundColor: COMMON_LIGHT_COLOR,
  },
  breakpoints: {
    maxTheSmallesMobile: Breakpoint.MaxTheSmallesMobile,
    maxTinyMobile: Breakpoint.MaxTinyMobile,
    maxSmallMobile: Breakpoint.MaxSmallMobile,
    minMobile: Breakpoint.MinMobile,
    onlyMobile: Breakpoint.OnlyMobile,
    maxMobile: Breakpoint.MaxMobile,
    minTablet: Breakpoint.MinTablet,
    onlyTablet: Breakpoint.OnlyTablet,
    minMidTablet: Breakpoint.MinMidTablet,
    onlyMidTablet: Breakpoint.OnlyMidTablet,
    maxTablet: Breakpoint.MaxTablet,
    minDesktop: Breakpoint.MinDesktop,
    onlyDesktop: Breakpoint.OnlyDesktop,
    maxDesktop: Breakpoint.MaxDesktop,
    minLargeDesktop: Breakpoint.MinLargeDesktop,
    minHugeDesktop: Breakpoint.MinHugeDesktop,
  },
};
