import styled, { useTheme } from "styled-components";
import { Breakpoint } from "../../../enums/Breakpoint";
import { useMemo } from "react";
import useMedia from "../../../hooks/useMedia";
import Checkmark from "../../../customIcons/Checkmark";
import Mk1Typography from "../Mk1Typography";
import { useTranslation } from "../../../i18n";

const PERKS_LABELS = ["tix_search_form_perk_1", "tix_search_form_perk_2", "tix_search_form_perk_3"];

const ExternalLayoutPerks = () => {
  const isMaxTinyMobile = useMedia(Breakpoint.MaxTinyMobile);
  const isMaxSmallMobile = useMedia(Breakpoint.MaxSmallMobile);
  const isMaxMobile = useMedia(Breakpoint.MaxMobile);
  const { t } = useTranslation();
  const theme = useTheme();

  const perksLabels = useMemo(() => {
    const numberOfPerksShown = isMaxTinyMobile ? 1 : isMaxSmallMobile ? 2 : 3;
    const labels = isMaxMobile ? PERKS_LABELS.slice(0, numberOfPerksShown) : PERKS_LABELS;
    return labels.map((label) => t(label));
  }, [isMaxMobile, t, isMaxTinyMobile, isMaxSmallMobile]);

  return (
    <Perks>
      {perksLabels.map((translation) => (
        <PerkContainer color="">
          <Checkmark stroke={theme.checkmarkColor} />
          <StyledLabel defaultVariant="small"> {translation}</StyledLabel>
        </PerkContainer>
      ))}
    </Perks>
  );
};

const StyledLabel = styled(Mk1Typography)`
  line-height: 17px;
  display: contents;
  margin: auto;
`;

const Perks = styled.div`
  background: ${({ theme }) => theme.darkBackground};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-top: 11px;
  @media ${Breakpoint.MaxMobile} {
    padding-top: 10px;
    ${({ theme }) =>
      !!theme.form.container?.mobileBackgroundColor &&
      `background: ${theme.form.container?.mobileBackgroundColor};`}
  }
`;

const PerkContainer = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-right: 30px;
  }
  svg {
    margin: auto;
    margin-right: 5px;
  }
`;

export default ExternalLayoutPerks;
