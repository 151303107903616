import { DefaultTheme } from "styled-components";
import { Breakpoint } from "../enums/Breakpoint";
import { AvailableFont } from "../enums/AvailableFonts";

export const tixYellowTheme: DefaultTheme = {
  defaultBackground: "#FFFFFF",
  transparentDefaultBackground: "rgba(255, 255, 255, 0.5)",
  defaultColor: "#000000",
  greyColor: "#757575",
  errorColor: "#C00051",
  disabledColor: "#BFBFBF",
  darkBackground: "#E3E3E3",
  lightBackground: "#F2F2F2",
  progressColor: "#00AFEF",
  primaryBackground: "#2C53BA",
  primaryColor: "#FFFF",
  checkmarkColor: "#6EC566",
  regularFont: `"${AvailableFont.NexaRegular}", sans-serif`,
  heavyFont: `"${AvailableFont.NexaHeavy}", sans-serif`,
  sharpBorderRadius: "0px",
  ovalBorderRadius: "100px",
  searchFormTabs: {
    default: {
      activeBackgroundColor: "#E3E3E3",
    },
  },
  form: {
    textField: {
      placeholderColor: "#CCCCCC",
    },
    radioGroup: {
      disabled: "#c2c5c7",
    },
    switcher: {
      activeItemBackgroundColor: "#FCC002",
      activeTextColor: "#000000",
      inactiveTextColor: "#000000",
    },
    button: {
      primaryBackground: "#008eff",
      primaryColor: "#ffffff",
      primaryHoverBackground: "#0077D6",
      defaultHoverBackground: "#ffefcc",
    },
    selectField: {
      defaultItemHoverBackground: "#FEEFBF",
    },
    calendar: {
      mobileDayRectBackground: "#ffefcc",
      mobileDayHoverBackground: "#ffefcc",
      dayRectBackground: "#FFFFFF",
      hoverBackground: "#ffefcc",
      activeBackground: "#FCC002",
      selectedColor: "inherit",
    },
    dropdown: {
      boxShadow: "#00000033",
    },
    animatedRoundedSelect: {
      defaultBackgroundColor: "#ffffff",
      hoveredOptionBackground: "#fee4a5",
      selectedOptionBackground: "#FCC002",
    },
  },
  flightSearchForm: {
    backgroundColor: "#F2F2F2",
    flightTypeSelectedColor: "#FCC002",
    flightTypeEmptyColor: "#000000",
    buttonColor: "#008EFF",
  },
  mobileModal: {
    backgroundColor: "#F2F2F2",
    footerBackgroundColor: "#F2F2F2",
  },
  breakpoints: {
    maxTheSmallesMobile: Breakpoint.MaxTheSmallesMobile,
    maxTinyMobile: Breakpoint.MaxTinyMobile,
    maxSmallMobile: Breakpoint.MaxSmallMobile,
    minMobile: Breakpoint.MinMobile,
    onlyMobile: Breakpoint.OnlyMobile,
    maxMobile: Breakpoint.MaxMobile,
    minTablet: Breakpoint.MinTablet,
    onlyTablet: Breakpoint.OnlyTablet,
    minMidTablet: Breakpoint.MinMidTablet,
    onlyMidTablet: Breakpoint.OnlyMidTablet,
    maxTablet: Breakpoint.MaxTablet,
    minDesktop: Breakpoint.MinDesktop,
    onlyDesktop: Breakpoint.OnlyDesktop,
    maxDesktop: Breakpoint.MaxDesktop,
    minLargeDesktop: Breakpoint.MinLargeDesktop,
    minHugeDesktop: Breakpoint.MinHugeDesktop,
  },
};
