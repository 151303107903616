import { DateUtils } from "react-day-picker";
import { CustomDatePickerSource } from "../enums/CustomDatePicker";

export const isSelectingFirstDay = (
  day: Date,
  source: CustomDatePickerSource,
  fromDate?: Date,
  toDate?: Date
) => {
  const isBeforeFirstDay = !!fromDate && DateUtils.isDayBefore(day, fromDate);
  const isRangeSelected = source === CustomDatePickerSource.FROM_DATE && !!fromDate && !!toDate;
  return !fromDate || isBeforeFirstDay || isRangeSelected;
};
