import { FormikErrors, FormikTouched, getIn } from "formik";
import { forwardRef, useCallback, useMemo } from "react";
import {
  FlightsSearchFormValues,
  FlightsSearchFormValuesType as FlightType,
} from "../../../types/FlightsSearchFormValues";
import { isBefore } from "date-fns";
import { CustomDatePickerSource } from "../../../enums/CustomDatePicker";
import { DEFAULT_DISABLED_DAYS } from "../../../constants/flightsConstants";
import { useWizardContext } from "../WizardContext";
import { dispatchCustomEvent } from "../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../enums/WidgetEvents";
import CustomDateFields from "../../CustomDayPicker/CustomDateFields";

interface Props {
  boundsIndexes: number[];
  errors: FormikErrors<FlightsSearchFormValues>;
  touched: FormikTouched<FlightsSearchFormValues>;
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  onDateSelect?(): void;
  values: FlightsSearchFormValues;
  scrollControlled?: boolean;
}

const FlightDateField = forwardRef<HTMLButtonElement, Props>(
  ({ boundsIndexes, errors, touched, onChange, onDateSelect, values, scrollControlled }, ref) => {
    const { mobileModalAnimations, stopWizard } = useWizardContext();
    const departureFieldName = useMemo(() => {
      return `bounds[${boundsIndexes[0]}].departureDate`;
    }, [boundsIndexes]);

    const returnFieldName = useMemo(() => {
      return `bounds[${boundsIndexes[1]}].departureDate`;
    }, [boundsIndexes]);

    const isDateRange = useMemo(() => values.type === FlightType.Return, [values.type]);

    const shouldChangeOpenJawReturnDate = useCallback(
      (date: Date) => {
        return (
          values.type === FlightType.MultiCity &&
          boundsIndexes[0] === 0 &&
          !!values.bounds[1]?.departureDate &&
          isBefore(values.bounds[1].departureDate, date)
        );
      },
      [values.bounds, values.type, boundsIndexes]
    );

    const handleDayClick = useCallback(
      (date: Date, source: CustomDatePickerSource, isSelectingFirstDay: boolean) => {
        let valuesChanges: Record<string, any> = {};
        let shouldValidate;

        if (source === CustomDatePickerSource.FROM_DATE || !isDateRange || isSelectingFirstDay) {
          dispatchCustomEvent({
            widgetEvent: WidgetGATriggerEvents.FlightFromDateSelected,
            details: { date },
          });
          valuesChanges = { ...valuesChanges, [`bounds[${boundsIndexes[0]}].departureDate`]: date };
          if (!isDateRange) {
            if (shouldChangeOpenJawReturnDate(date)) {
              valuesChanges = {
                ...valuesChanges,
                [`bounds[1].departureDate`]: date,
              };
            }
          } else if (
            getIn(values, `bounds[${boundsIndexes[1]}].departureDate`) &&
            !isBefore(date, getIn(values, `bounds[${boundsIndexes[1]}].departureDate`))
          ) {
            valuesChanges = {
              ...valuesChanges,
              [`bounds[${boundsIndexes[1]}].departureDate`]: undefined,
            };
            shouldValidate = false;
          }
        } else {
          dispatchCustomEvent({
            widgetEvent: WidgetGATriggerEvents.FlightToDateSelected,
            details: { date },
          });
          valuesChanges = {
            ...valuesChanges,
            [`bounds[${boundsIndexes[1]}].departureDate`]: date,
          };
        }
        onChange(valuesChanges, shouldValidate);

        if (boundsIndexes.length === 1 || !isSelectingFirstDay) {
          // Runs after selecting last date of bound
          onDateSelect?.();
        }
      },
      [isDateRange, onChange, boundsIndexes, values, shouldChangeOpenJawReturnDate, onDateSelect]
    );

    const disabledDays = useMemo(
      () => ({
        ...DEFAULT_DISABLED_DAYS,
        ...(values.type === FlightType.MultiCity && boundsIndexes[0] > 0
          ? {
              before:
                values.bounds[boundsIndexes[0] - 1]?.departureDate || DEFAULT_DISABLED_DAYS.before,
            }
          : {}),
      }),
      [boundsIndexes, values.bounds, values.type]
    );

    const isLastFieldInRow = useMemo(
      () => boundsIndexes[0] > 0 && values.type === FlightType.MultiCity,
      [boundsIndexes, values.type]
    );

    return (
      <CustomDateFields
        ref={ref}
        onChange={handleDayClick}
        shouldShowRange={isDateRange}
        errors={errors}
        touched={touched}
        values={values}
        fromPlaceholderLabel="tix_search_form_departure_date"
        toPlaceholderLabel="tix_search_form_return_date"
        disabledDays={disabledDays}
        fromFieldName={departureFieldName}
        toFieldName={returnFieldName}
        isLastFieldInRow={isLastFieldInRow}
        scrollControlled={scrollControlled}
        mobileModalAnimations={mobileModalAnimations}
        onMobileModalClose={stopWizard}
      />
    );
  }
);

export default FlightDateField;
